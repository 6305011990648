import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";

@Component({
  selector: 'app-card-checked-label',
  standalone: true,
  imports: [CommonModule, InlineSVGModule],
  template:`
    <div class="card-checked-label">
      <span class="d-flex svg-24"
            [inlineSVG]="type === 'checked' ? icon.checked : icon.cross"></span>
      <div>
        <h6 class="font-14px tc-label fw-500">
          {{ label }}
        </h6>
        <div class="font-12px">
          {{ desc }}
        </div>
      </div>
    </div>
  `,
  styles:[
    `
      .card-checked-label {
        padding: 1rem 20px;
        display: flex;
        align-items: center;
        gap: 12px;
        background-color: #F9F9F9;
        border-radius: 9px;
      }
    `
  ]
})
export class CardCheckedLabelComponent {

  @Input()
  public label!: string;

  @Input()
  public desc!: string;

  public type : 'checked' | 'cross' = 'checked';

  public icon: {checked: string, cross: string} = {
    checked: `${ROAM_IMAGE_SVG}/check.svg`,
    cross: `${ROAM_IMAGE_SVG}/ic-close-bg-grey.svg`
  }

}
