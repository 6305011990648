import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { CommonModule } from "@angular/common";
import { getFileIcon } from "@app/shared/components/file-config/shared/utils/file-word-masking.util";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  selector: 'app-attachment-card',
  template:
    `<div class="attachment-view">
         <div class="align-center gap-8">
           <span class="d-flex svg-24" [inlineSVG]="icon"></span>
           <span> {{ fileName }}</span>
         </div>
        <div class="delete align-center" (click)="onDelete.emit()" *ngIf="showDelete">
          <img class="app-closed" [src]="_icon.binRed.src" [alt]="_icon.binRed.alt">
        </div>
     </div>
    `,
  styles:[
    `.attachment-view {
      height: 42px;
      padding: 0 16px;
      background: white;
      border-radius: 10px;
      border: 1px solid  #dddbde;
      display: inline-flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      color: #1c1c1c;
    }
    .delete {
      cursor: pointer;
      border-left: 1px solid rgb(214, 211, 215);
      padding-left: 8px;
    }
    `
  ],
  imports:[
    CommonModule,
    InlineSVGModule
  ]
})
export class AttachmentCardComponent {

  @Input()
  public fileName: string = '';

  @Input()
  public type: string = '';

  @Input()
  public showDelete: boolean = false;

  @Output()
  public onDelete: EventEmitter<Event> = new EventEmitter<Event>();

  public _icon = iconLib;

  get icon(): string{
    const type = this.type.includes('/') ? this.type.split('/')[1] : this.type;
    return getFileIcon(type)
  }
}
