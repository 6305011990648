import { Component, output } from "@angular/core";
import {
  ButtonAddUnderlineComponent
} from "@app/shared/components/button/button-add-underline/button-add-underline.component";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { NgIf } from "@angular/common";

@Component({
  selector: 'toggle-collapse',
  standalone: true,
  imports: [ButtonAddUnderlineComponent, NgIf, RoamIconComponent],
  template: `
    <div class="tc-primary font-14px fw-500 c-pointer align-center gap-8"
         (click)="onClick.emit($event); isCollapse = !isCollapse">
      <div class="icon" [class.toggle]="isCollapse">
        <roam-icon name="collapse" color="primary"/>
      </div>
      <span class="text-underline">
        {{ isCollapse ? 'Open All' : 'Collapse All'}}
      </span>
    </div>
  `,
  styles: `
    .toggle {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  `
})
export class ToggleCollapseComponent {
  onClick = output<Event>();
  isCollapse: boolean = false;
}
