import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'button-radius',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <button class="btn-base"
            [disabled]="disabled"
    >
      <roam-icon [name]="name" size="18" 
        [color]="disabled ? 'disabled' : ''"
        (onClick)="disabled ? null : onClick.emit()"></roam-icon>
    </button>
  `,
  styles:[`
    :host {
      min-width: 40px;
      border-radius: 12px;
      border: 0.5px solid #D6D3D7;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
    }

    .btn-base:disabled {
      background-color: transparent
    }
  `],
})
export class ButtonRadiusComponent {
  @Input()
  public name!: string;

  @Input()
  public disabled!: boolean;

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();
}
