import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'input-group',
  standalone: true,
  imports: [CommonModule],
  template:`
    <div class="input-group">
      <input class="input-control" value="https://app.box.com/s/8wht52l0snyrzkq9cadeeote91oplnp6">
      <button *ngIf="append">Copy</button>
    </div>
  `,
  styles:[
    `
      .input-group {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
      }

      .input-control {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        border-radius: 8px 0px 0px 8px;
        border: 0.6px solid  #D6D3D7;
        background: #FFF;
        padding: 9px 12px;
      }

      button {
        border: none;
        border-top: 1px solid #D6D3D7;
        border-right: 1px solid #D6D3D7;
        border-bottom: 1px solid #D6D3D7;
        border-radius: 0 8px 8px 0;
        padding: 9px 12px;
        background: #ffffff;
      }


    `
  ]
})
export class InputGroupComponent {
  @Input()
  public append: boolean = true;
}
