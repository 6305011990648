import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamAccordionOneComponent } from './roam-accordion-one/roam-accordion-one.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { InlineSVGModule } from "ng-inline-svg-2";
import { RoamAccordionTwoTemplateComponent } from './roam-accordion-two-template/roam-accordion-two-template.component';
import { RoamAccordionTemplateComponent } from './roam-accordion-template/roam-accordion-template.component';



@NgModule({
  declarations: [
    RoamAccordionOneComponent,
    RoamAccordionTwoTemplateComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    InlineSVGModule,
    RoamAccordionTemplateComponent
  ],
  exports: [
    RoamAccordionOneComponent,
    RoamAccordionTwoTemplateComponent,
    RoamAccordionTemplateComponent
  ]
})
export class RoamAccordionModule { }
