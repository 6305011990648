import { Component, forwardRef, Input, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from "@angular/forms";

@Component({
  standalone: true,
  selector: 'app-input-flat',
  template: `
    <div class="search-box">
      <label class="input-search">
        <input type="text" [placeholder]="placeholder"
               [value]="searchKeyword"
               (input)="onInput($event)"
               (blur)="onBlur()">
      </label>
    </div>
  `,
  styles: [
    `
      .input-search {
        height: 40px;
        width: 100%;
        padding: 0.6rem 8px;
        border-radius: 8px;
        border: 1px solid #D6D3D7;
        display: flex;
      }

      input {
        width: 100%;
        border: none;
        background-color: initial;
        font-size: 0.875rem;
      }

      input::placeholder {
        color: #949494;
        font-family: "Poppins", sans-serif;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFlatComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFlatComponent),
      multi: true,
    },
  ],
})
export class InputFlatComponent implements ControlValueAccessor, Validator {

  @Input()
  public placeholder: string = '';

  @Output()
  public valueChange: EventEmitter<any> = new EventEmitter();

  public searchKeyword: string = '';

  // Implementing ControlValueAccessor interface
  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: string) {
    this.searchKeyword = val;
    this.onChange(val);
    this.onTouch(val);
    this.valueChange.emit(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  // Implementing Validator interface
  validate(control: AbstractControl): ValidationErrors | null {
    // You can add custom validation logic here if needed
    return null;
  }

  // Event handlers
  onInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.value = inputValue;
  }

  onBlur() {
    this.onTouch(this.searchKeyword);
  }
}
