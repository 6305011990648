import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "app-roam-toggle-slider",
  imports: [CommonModule],
  template: `
    <div class="align-center gap-16">
      <label class="align-center gap-12 c-pointer" [for]="'checkbox' + name">
        <span class="switch">
          <input
            type="checkbox"
            [id]="'checkbox' + name"
            name="name"
            [checked]="isChecked"
            (change)="toggled()" />
          <span class="slider round"></span>
        </span>
        <span class="font-14px tc-black" [class]="labelClass">{{ label }}</span>
      </label>
    </div>
  `,
  styles: [
    `
      .switch {
        display: inline-block;
        height: 32px;
        position: relative;
        width: 50px;
      }

      .switch input {
        display: none;
      }

      .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
      }

      .slider:before {
        content: "";
        position: absolute;
        background-color: #fff;
        left: 2px;
        bottom: 2px;
        height: 28px;
        width: 28px;
        transition: 0.1s;
      }

      input:checked + .slider {
        background-color: #91466a;
      }

      input:checked + .slider:before {
        transform: translateX(18px);
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamToggleSliderComponent),
      multi: true,
    },
  ],
})
export class RoamToggleSliderComponent implements ControlValueAccessor {
  @Input()
  public name!: string | number;

  @Input()
  public label: string = "";

  @Input()
  public labelClass: string = "fw-500";

  @Output()
  public OnChangeToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public isChecked: boolean = false;

  // ControlValueAccessor methods
  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(value: any): void {
    if (value !== undefined) {
      this.isChecked = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement this if you want to handle disabling the checkbox
  }

  toggled(): void {
    this.isChecked = !this.isChecked;
    this.onChange(this.isChecked);
    this.onTouched();
    this.OnChangeToggle.emit(this.isChecked);
  }
}
