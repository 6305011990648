import { CommonModule } from '@angular/common';
import { Component, input, output, signal } from "@angular/core";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";

@Component({
  selector: "button-mat-action",
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template: `
    
      <div class="align-center gap-8">
        <roam-icon [name]="icon()" size="18" [color]="color()"/>
        <span class="font-14px fw-600">
          {{ label() }}
        </span>
      </div>
    
  `
})
export class ButtonMatActionComponent {
  icon = input<string>('');
  label = input<string>('');
  color = input<string>('');
  onAction = output();
}
