import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";

@Component({
  selector: 'button-icon',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <button [class]="'btn btn-r-std ' + type"
            [class.bdr]="round"
            [class.disable-light]="disableLight && disabled"
            (click)="onClick.emit()"
            [disabled]="disabled"
    >
      <roam-icon [name]="icon" [size]="size" [color]="disabled ? 'glight' : color"></roam-icon>
      <span [class]="textColor">{{ label }}</span>
    </button>
  `,
})
export class ButtonIconComponent {
  @Input()
  public icon!: string;

  @Input()
  public label!: string;

  @Input()
  public size: string = '16';

  @Input()
  public round: boolean = true;

  @Input()
  public textColor!: string;

  @Input()
  public color: string = 'primary';

  @Input()
  public disabled: boolean = false;

  @Input()
  public disableLight: boolean = false;

  @Input()
  set type(type: string) {
    this.buttonType = this.buttonConfigs[type];
  }

  get type(): string {
    return this.buttonType || 'btn-o-primary'
  }

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();


  public buttonType!: string;

  public buttonConfigs: WordDictionary = {
    'primary': 'btn-primary',
    'outline-primary': 'btn-o-primary',
    'outline-gray': 'btn-o-glight'
  }

}
