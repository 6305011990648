import { Component, EventEmitter, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'button-edit-delete',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <div class="align-center gap-12">
      <roam-icon name="edit" (onClick)="edit.emit($event)"></roam-icon>
      <roam-icon name="bin" color="warning" (onClick)="delete.emit($event)"></roam-icon>
    </div>
  `,
  styleUrls: ['./button-edit-delete.component.scss']
})
export class ButtonEditDeleteComponent {
  @Output()
  public edit: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public delete: EventEmitter<Event> = new EventEmitter<Event>();
}
