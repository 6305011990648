import { Component, input } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { NgIf } from "@angular/common";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  imports: [MatExpansionModule, NgIf, InlineSVGModule],
  selector: "roam-accordion-template",
  template: `
    <mat-accordion [class]="'roam-accordion ' + class()">
      <mat-expansion-panel hideToggle [expanded]="expand()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="header-center">
              <div class="align-center">
                <div class="align-center gap-12">
                  <div class="font-16px fw-500">
                    {{ label() }}
                  </div>
                </div>
              </div>

              <div class="align-center gap-10">
                <ng-container>
                  <ng-content select="[textRight]"></ng-content>
                </ng-container>
                <div class="d-flex mt-4 caret" [inlineSVG]="caret"></div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mt-10">
          <ng-content></ng-content>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styleUrls: ["roam-accordion-template.component.scss"],
})
export class RoamAccordionTemplateComponent {
  expand = input<boolean>(true);
  label = input<string>("");
  class = input<string>("");

  public caret = iconLib.caretTop.src;
}
