import { Component, EventEmitter, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-button-edit',
  template:`
    <div class="font-14px tc-primary align-center gap-5 c-pointer">
      <span class="svg-16 d-flex" [inlineSVG]="icon.editPrimary.src"></span>
      <span class="text-underline fw-500" (click)="onClick.emit()">Edit</span>
    </div>
  `
})
export class ButtonEditComponent {

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;

}
