import {
  booleanAttribute,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
} from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  selector: "app-button-add",
  template: `
    <button
      class="btn btn-o-primary btn-r-std"
      [class.h-45]="type === 'md'"
      [class.bdr]="round"
      (click)="onAdd.emit()">
      <span class="d-flex icon" [inlineSVG]="icon.plusRed.src"></span>
      <span>{{ label }}</span>
    </button>
  `,
  imports: [InlineSVGModule],
  styles: [
    `
      .bdr {
        border-radius: 12px !important;
        gap: 4px;
      }

      ::ng-deep .bdr svg {
        width: 16px;
        height: 16px;
      }
    `,
  ],
})
export class ButtonAddComponent {
  @Input()
  public label: string = "";

  round = input(false, { transform: booleanAttribute });

  @Input()
  public type: "default" | "md" = "default";

  @Output()
  public onAdd: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;
}
