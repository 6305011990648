import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: "app-attachment-upload-button",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="attachment">
      <input
        type="file"
        #fileInput
        id="uploadFile"
        (change)="selectedFile($event)"
      />
      <ng-container>
        <span class="fw-600 tc-primary">Drag here</span> or
        <button mat-button class="upload-button" (click)="openFileUpload()">
          <img [src]="icon.upload.src" alt="Upload Icon" class="mr-8" />
          <span class="fw-400">Upload File</span>
        </button>
      </ng-container>
    </div>
  `,
  styleUrls: ["./attachment-upload-button.component.scss"],
})
export class AttachmentUploadButtonComponent {
  @ViewChild("fileInput", { static: false }) fileInput!: ElementRef;

  @Output()
  public onUploadedFiles: EventEmitter<File> = new EventEmitter<File>();

  public icon = iconLib;
  public files: File[] = [];

  public openFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  public selectedFile(event: any): void {
    const File = (event.target as HTMLInputElement).files;
    this.onUploadedFiles.emit(File![0]);
    event.target.value = null;
  }
}
