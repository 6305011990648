import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-roam-accordion-two-template',
  template:`
    <mat-accordion class="roam-accordion" [class.disabled]="disabled">
      <mat-expansion-panel hideToggle  [expanded]="expand" [disabled]="disabled">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="header-center header-border">
              <div class="align-center">
                <div *ngIf="icon" class="mr-8">
                  <span class="d-flex svg-28" [inlineSVG]="icon"></span>
                </div>
                <div class="align-center gap-12">
                  <div class="font-18px fw-500">
                    {{ title }}
                  </div>

                  <div *ngIf="isEdit" class="tc-primary text-underline"
                       (click)="editAccordion($event)">Edit</div>
                </div>

              </div>

              <ng-container *ngIf="disabled">
                <ng-content select="[textRight]"></ng-content>
              </ng-container>

              <div *ngIf="!disabled" class="d-flex mt-4 caret" [inlineSVG]="caret"></div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pt-24">
          <ng-content select="[template]"></ng-content>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  `,
  styleUrls: ['./roam-accordion-two-template.component.scss']
})
export class RoamAccordionTwoTemplateComponent {

  @Input()
  public expand!: boolean;

  @Input()
  public disabled?: boolean;

  @Input()
  public icon: string | undefined = '';

  @Input()
  public title: string = '';

  @Input()
  public isEdit!: boolean;

  @Output()
  public onEdit: EventEmitter<any> = new EventEmitter<any>();

  public caret = iconLib.caretTop.src;

  public editAccordion(event: Event): void {
    event.stopPropagation();
    this.onEdit.emit()
  }
}
