import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { ROAM_VALIDATORS } from "@app/core/const/validator-error-message.const";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "@app/shared/material/material.module";

@Component({
  standalone: true,
  imports:[CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  selector: 'app-roam-text-area',
  template: `
    <mat-form-field appearance="outline" class="w-100"
                    [class]="class"
                    [style]="style"
                    [class.disabled]="disabled"
                    [ngClass]="{'invalid': control?.invalid }">
      <mat-label>{{ name }}</mat-label>
      <textarea matInput class="font-14px" [formControl]="formControl"></textarea>
    </mat-form-field>

    <div class="mat-error">
      <span *ngFor="let validator of validators[ctrName]">
          {{ validator.message }}
      </span>
    </div>

  `,
  styles:[
    `
      textarea {
        resize: none;
      }
      
      textarea::placeholder {
        color:#9E9E9E !important;
      }

      ::ng-deep .disabled.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        background-color: #EEEEEE!important;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamTextAreaComponent),
      multi: true,
    },
  ]
})
export class RoamTextAreaComponent implements ControlValueAccessor{

  @Input()
  public name: string ='';

  @Input()
  public control: AbstractControl | FormControl | null = null;

  @Input()
  public class: string = '';

  @Input()
  public style: string = '';

  @Input()
  public ctrName: string = '';

  @Input()
  public disabled: boolean = false;

  @Input()
  set inputValue(value: any) {
    this.formControl.setValue(value)
  }

  public formControl: FormControl = new FormControl();
  public validators: any = ROAM_VALIDATORS;

  get isInvalid() {
    return this.formControl.invalid && this.formControl.touched;
  }

  // ControlValueAccessor implementation
  writeValue(value: any): void {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControl.statusChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

}
