import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
  styles:[
      `
      textarea {
        width: 100%;
        height: 88px;
        padding: 11px;
        border-radius: 9px;
        border: 1px solid #D6D3D7;
        background-color: #ffffff;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        resize: none;
      }

      textarea::placeholder {
        color: #9E9E9E;
      }
    `

  ]
})
export class InputTextAreaComponent {

  @Input()
  public placeholder: string = '';
}
