import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-button-upload',
  template:`
    <div>
      <ng-container>
        <label class="tc-black font-14px"> Attachment </label>
        <div class="mt-8">
          <input type="file" id="fileInput" style="display: none"
                 (change)="selectedFile($event)"/>
          <label for="fileInput" class="file-label" *ngIf="files.length === 0">
            <img [src]="icon.upload.src" alt="upload">
            <span>Upload File</span>
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf="files.length > 0">
        <div class="align-center gap-10 f-wrap">
          <div *ngFor="let file of files; let file_i = index">
            <app-attachment-card [fileName]="file.name"
                                 [type]="file.type"
                                 [showDelete]="true"
                                 (onDelete)="deleteFile(file_i)"
            ></app-attachment-card>
          </div>
          <div class="tc-primary fw-500 c-pointer closed-menu" (click)="openFileUpload('fileInput')">+ Add File</div>
        </div>
      </ng-container>
    </div>
  `,
  styles:[
      `
      .file-label {
        cursor: pointer;
        padding: 10px 1.25rem;
        background-color: #ffffff;
        color: #1c1c1c;
        border: 1px solid #91466A;
        border-radius: 25px;
        display: inline-flex;
        gap: 8px;
        align-items: center;
      }
    `
  ]
})
export class ButtonUploadComponent {

  @Input()
  public files: File[] = [];

  @Output()
  public onUploadedFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  public icon = iconLib;

  public openFileUpload(id: string): void {
    document?.getElementById(id)?.click();
  }

  public selectedFile(event: any): void {
    const File = (event.target as HTMLInputElement).files;
    this.files.push(File![0]);
    this.onUploadedFiles.emit(this.files);
  }

  public deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.onUploadedFiles.emit(this.files);
  }
}
