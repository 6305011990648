import { Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { ROAM_VALIDATORS } from "@app/core/const/validator-error-message.const";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'input-dropdown',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, RoamIconComponent],
  templateUrl: './input-dropdown.component.html',
  styles:[
    `
      input {
        width: 93%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .chevron-icon {
        position: absolute;
        right: -4px;
        top: 14px;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDropdownComponent),
      multi: true
    }
  ]
})
export class InputDropdownComponent {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() name: string = '';
  @Input() className: string = '';
  @Input() readOnly: boolean = false;
  @Input() placeholder: string = '';
  @Input() floatingLabel: boolean = true;
  @Input() public ctrName: string = '';
  @Input() public ctr: AbstractControl | FormControl | null = null;

  @Input()
  public numberOnly: boolean = false;

  @Input()
  public currency: boolean = false;

  public validators: any = ROAM_VALIDATORS;

  currentValue: string = '';
  onChange: any = () => { }
  onTouch: any = () => { }

  set value(val: string) {
    this.currentValue = val;
    this.onChange(val);
    this.onTouch(val);
    this.valueChange.emit(this.currentValue);
  }

  constructor() { }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  onValueChange() {
    this.value = this.currentValue;
    this.valueChange.emit(this.currentValue);
  }
}
