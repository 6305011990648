import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  selector: 'app-button-add-underline',
  standalone: true,
  imports: [
    CommonModule,
    InlineSVGModule
  ],
  template:`
    <div class="tc-primary font-14px fw-500 c-pointer align-center"
         (click)="onClick.emit()">
      <span class="plr-10" *ngIf="!icon">+</span>
      <span *ngIf="icon" class="d-flex mr-8" [inlineSVG]="icon">
      </span>
      <span class="text-underline">
        {{ label }}
      </span>
    </div>
  `,
})
export class ButtonAddUnderlineComponent {

  @Input()
  public label: string = 'Add';

  @Input()
  public icon: string = '';

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
