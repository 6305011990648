import { Component, EventEmitter, Output } from "@angular/core";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-button-save-exit',
  standalone: true,
  imports: [CommonModule],
  template:`
      <div class="save-exit" (click)="onClick.emit()">
          Save & Exit
      </div>
  `,
  styles:[
      `
          .save-exit {
              border-radius: 999px;
              border: 1px solid rgba(143, 134, 147, 0.30);
              display: inline-flex;
              padding: 4px 24px;
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
          }
    `
  ]
})
export class ButtonSaveExitComponent {
  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
