import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatIconModule } from "@angular/material/icon";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-button-dropdown',
  standalone: true,
  imports: [CommonModule, InlineSVGModule, MatIconModule, MatFormFieldModule, MatSelectModule, FormsModule],
  template:`
    <mat-form-field appearance="outline"
                    class="roam-field btn-dropdown radius-8"
                    [class.inline]="type === 'inline'"
    >
      <mat-select [placeholder]="placeholder"
                  [hideSingleSelectionIndicator]="true"
                  [panelClass]="'panel-dropdown'"
                  [(ngModel)]="selected"
                  (selectionChange)="selectionChange($event)">

        <mat-select-trigger>
          <div class="align-center gap-10 h-45">
            <span class="d-flex svg-18" [inlineSVG]="selectedOption.icon"></span>
            <span>{{ selectedOption.name }}</span>
          </div>
        </mat-select-trigger>

        <mat-option class="font-14px" *ngFor="let option of options"
                    [class]="'mat-checkbox-'+option.id"
                    [value]="option.id">

          <div class="align-center gap-10 h-45">
            <span *ngIf="showOptionIcon"  class="d-flex svg-20" [inlineSVG]="option.icon"></span>
            <span class="fw-500">{{ option.name }}</span>
          </div>
        </mat-option>
      </mat-select>

        <span matSuffix>
          <mat-icon class="chevron-dropdown">expand_more</mat-icon>
        </span>

    </mat-form-field>
  `,
  styles:[
      `
      .btn-dropdown {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
      }

      .inline {
        display: inline-flex;
      }

      .svg-20 svg {
        height: 20px!important;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonDropdownComponent),
      multi: true
    }
  ]
})
export class ButtonDropdownComponent implements OnInit{

  @Input()
  public icon: string = ''

  @Input()
  public label!: string;

  @Input()
  public placeholder!: string;

  @Input()
  public options: IIdNameIcon[] = [];

  @Input()
  public panelClass: string = '';

  @Input()
  public type: 'inline' | 'flex' = 'inline'

  @Input()
  public selected: string = 'persona';

  @Input()
  public showOptionIcon: boolean = false;

  @Output() selectedModelChange: EventEmitter<string> = new EventEmitter<string>();

  public selectedOption: IIdNameIcon = {id: '', name: 'Select an option', icon: ''};

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    if (this.options.length > 0 && this.selected) {
      this.setDisplayText();
    }
  }

  private setDisplayText(): void {
    const foundOption = this.options.find(option => option.id === this.selected);
    this.selectedOption = foundOption ? foundOption : {id: '', name: 'Select an option', icon: ''};
  }

  // public selectionChange(event: MatSelectChange): void {
  //   this.selectedOption = this.options.find(option => option.id === this.selected)!;
  //   this.selectedModelChange.emit(this.selected);
  // }

  writeValue(value: any) {
    this.selected = value;
    this.setDisplayText();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }

  // ngOnInit(): void {
  //   // Existing ngOnInit logic...
  // }

  selectionChange(event: MatSelectChange): void {
    this.selectedOption = this.options.find(option => option.id === this.selected)!;
    this.onChange(this.selected);
    this.onTouch();
  }
}
