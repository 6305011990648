import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSearchOneComponent } from './input-search-one/input-search-one.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputDirectiveModule } from "@app/shared/directives/input-directive/input-directive.module";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";
import { MaterialModule } from "@app/shared/material/material.module";
import { InputFlatComponent } from "@app/shared/components/input/input-flat/input-flat.component";
import { InputDropdownComponent } from "@app/shared/components/input/input-dropdown/input-dropdown.component";
import { InputGroupComponent } from "@app/shared/components/input/input-group/input-group.component";
import {
  RoamToggleSliderComponent
} from "@app/shared/components/button/roam-toggle-slider/roam-toggle-slider.component";

@NgModule({
  declarations: [
    InputTextAreaComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    InputDirectiveModule,
    RoamLoaderModule,
    MaterialModule,
    InputFlatComponent,
    InputSearchOneComponent,
    InputDropdownComponent,
    RoamSelectComponent,
    RoamInputComponent,
    InputGroupComponent,
    RoamDatepickerComponent,
    RoamToggleSliderComponent
  ],
  exports: [
    RoamInputComponent,
    RoamSelectComponent,
    RoamDatepickerComponent,
    InputSearchOneComponent,
    InputTextAreaComponent,
    InputDirectiveModule,
    InputFlatComponent,
    InputSearchOneComponent,
    InputDropdownComponent,
    InputGroupComponent,
    RoamToggleSliderComponent
  ]
})
export class InputModule { }
