<mat-form-field appearance="outline" class="white-bg label-field w-100 input-dropdown" [class]="className"
                [ngClass]="{
                  'invalid': ctr && ctr.invalid,
                  'ng-touched': ctr && ctr.touched
                }"
                [floatLabel]="!floatingLabel ? 'always': 'auto'">

  <mat-label *ngIf="floatingLabel">{{ name }}</mat-label>
  <input matInput
         [class.text-right]="currency"
         [(ngModel)]="currentValue"
         (ngModelChange)="onValueChange()"
         [placeholder]="placeholder"
         readonly/>

  <div class="chevron-icon">
    <roam-icon name="chevron-down" size="12" color="base"/>
  </div>
</mat-form-field>

<div class="mat-error">
    <span *ngFor="let validator of validators[ctrName]">
        {{ validator.message }}
    </span>
</div>

