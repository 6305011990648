import { Component, input, output } from '@angular/core';
import { RoamIconComponent } from '../../roam-icon/roam-icon.component';

@Component({
  selector: 'button-manage',
  standalone: true,
  imports: [RoamIconComponent],
  template: `
     <button 
      class="btn btn-o-primary align-center gap-8 mr-0"
      (click)="onClick.emit()">
        <roam-icon [name]="icon()" size="18" [color]="color()!" />
        {{ label() }}
      </button>
  `,
  styles: ``
})
export class ButtonManageComponent {
  icon = input<string>();
  label = input<string>();
  color = input<string>('primary');
  onClick = output();
}
