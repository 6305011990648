import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'button-rounded',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <button class="btn-rounded"
            [style.width]="width"
            (click)="onClick.emit()"
    >
      <roam-icon [name]="icon" [size]="'svg-16'"></roam-icon>
      {{ label }}
    </button>
  `,
  styles:[
    `
      .btn-rounded {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:8px;
        max-height: 37px;
        background: #ffffff;
        border: 1px solid #CCC;
        border-radius: 99px;
        padding: 4px 12px;
        font-size: 12px;
        cursor: pointer;
      }

      .btn-rounded:hover {
        background: #F3F3F3;
      }
    `
  ]
})
export class ButtonRoundedComponent {

  @Input()
  public icon: string = '';

  @Input()
  public label: string = '';

  @Input()
  public width: string = '';

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
