import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: "app-roam-accordion-one",
  template: `
    <mat-accordion class="roam-accordion">
      <mat-expansion-panel hideToggle [expanded]="expand">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="header-center">
              <div class="align-center">
                <div class="number-counter">
                  {{ counter }}
                </div>

                <div class="ml-12 align-center gap-12">
                  <div class="font-18px fw-500">
                    {{ title }}
                  </div>
                  <div
                    *ngIf="isEdit"
                    class="tc-primary text-underline"
                    (click)="onEdit.emit()"
                  >
                    Edit
                  </div>
                </div>
              </div>
              <div class="d-flex gap-8">
                <div *ngIf="isDelete" (click)="delete($event)">
                  <div class="d-flex mt-4" [inlineSVG]="icon.binRed.src"></div>
                </div>
                <div
                  class="d-flex mt-4 caret"
                  [inlineSVG]="icon.caretTop.src"
                ></div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="description">
          <div [innerHTML]="description"></div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styleUrls: ["./roam-accordion-one.component.scss"],
})
export class RoamAccordionOneComponent {
  @Input()
  public counter!: number;

  @Input()
  public title!: string;

  @Input()
  public description: string = "";

  @Input()
  public expand!: boolean;

  @Input()
  public isDelete!: boolean;

  @Input()
  public isEdit!: boolean;

  @Output()
  public onEdit: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public onDelete: EventEmitter<any> = new EventEmitter<any>();

  public icon = iconLib;

  public delete(event: Event): void {
    event.stopPropagation();
    this.onDelete.emit();
  }
}
